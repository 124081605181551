const styles = {
  modalRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, .7)",
    left: 0,
    top: 0,
    zIndex: 999,
    overflowY: "scroll",
    padding: "24px",
    fontFamily: '"Karla", "Helvetica Neue", sans-serif',
    boxSizing: "border-box",
    WebkitFontSmoothing: "antialiased",
    mozOsxFontSmoothing: "grayscale",
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: 700,
    color: "#1D2129",
    marginBottom: "16px",
  },
  modalTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  modalTitleIcon: {
    marginRight: "6px",
  },
  modalChildren: {
    backgroundColor: "white",
    padding: "24px",
    borderRadius: "3px",
    boxShadow: "0 2px 24px 0 rgba(0,0,0,0.10), 0 2px 28px 0 rgba(0,0,0,0.16)",
    maxHeight: "100%",
    overflowY: "auto",
    width: "592px",
    height: "auto",
    color: "#4F4F4F",
  },
  timeoutModalInfoSpan: {
    fontWeight: 700,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#F44336",
    width: "35px",
  },
  timeoutModalInfo: {
    fontFamily: '"Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: "16px",
    marginBottom: "20px",
  },
  buttonRow: {
    "> * + *": {
      marginLeft: "20px",
    },
  },
  buttonChildren: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "left",
    fontSize: "16px",
  },
  plainButton: {
    padding: "10px 14px",
    minWidth: 0,
    backgroundColor: "transparent",
    borderRadius: "3px",
    borderWidth: 0,
    fontWeight: 700,
    color: "#A7A7A7",
    border: 0,
    cursor: "pointer",
    position: "relative",
    flexShrink: 0,
    marginLeft: "20px",
    fontSize: "16px",
  },
  primaryButton: {
    padding: "10px 14px",
    minWidth: "110px",
    backgroundColor: "#03A9F4",
    color: "white",
    hoverColor: "white",
    borderColor: "transparent",
    borderRadius: "3px",
    borderWidth: "1px",
    fontWeight: 700,
    border: "2px solid transparent",
    cursor: "pointer",
    position: "relative",
    flexShrink: 0,
  },
};

export default styles;
