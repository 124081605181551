import React from "react";
import Svg, { Path } from "svgs";

const Warning = ({ width, height, fill, style }) => {
  return (
    <div style={style}>
      <Svg
        height={height || 24}
        width={width || 24}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={fill || "currentColor"}
      >
        <Path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
      </Svg>
    </div>
  );
};

export default Warning;
