import React, { Component } from "react";
import { fetchLoggedInUser } from "../auth/auth-actions";
import { connect } from "react-redux";
import config from "../config";
import client from "../client";
import LoginForm from "./LoginForm";
import { performAuthenticatedRedirect } from "./auth-redirects";

const loggedIn = () =>
  sessionStorage.getItem("id_token") &&
  sessionStorage.getItem("access_token") &&
  sessionStorage.getItem("slug");

class Login extends Component {
  state = {
    hash: false,
    email: "",
    password: "",
  };

  componentDidMount() {
    if (loggedIn()) {
      performAuthenticatedRedirect(sessionStorage.getItem("slug"));
    }
  }

  componentDidUpdate() {
    const { user } = this.props;
    if (!user.get("error") && user.get("data")) {
      const hasAnyRepRoles = user
        .getIn(["data", "user", "roles"])
        .some(
          (role) =>
            ["supplier_representative", "distributor_representative"].indexOf(
              role
            ) >= 0
        );

      const isDeactivated = user.getIn(["data", "user", "deactivated_at"]);

      if (
        (hasAnyRepRoles && user.getIn(["data", "user", "roles"]).size === 1) ||
        isDeactivated
      ) {
        this.props.history.push("/no-permission");
      } else {
        const subdomains = user.getIn(["data", "user", "subdomains"]);
        if (subdomains.size === 1) {
          const slug = subdomains.getIn([0, "slug"]);
          sessionStorage.setItem("slug", slug);
          performAuthenticatedRedirect(slug);
        } else {
          this.props.history.push("/choose-tenant");
        }
      }
    }

    if (user.getIn(["error", "isEmailAutomationAccount"])) {
      this.props.history.push("/no-permission");
    }
  }

  login = async () => {
    try {
      const response = await client.post("token", {
        grant_type: "password",
        username: this.state.email,
        password: this.state.password,
        client_id: config.auth.clientId,
      });

      if (response.ok) {
        const json = await response.json();
        this.setState(() => ({ json }));
        sessionStorage.setItem("access_token", json.access_token);
        sessionStorage.setItem("id_token", json.id_token);
        sessionStorage.setItem("refresh_token", json.refresh_token);

        this.props.dispatch(fetchLoggedInUser(json.id_token));
      } else {
        this.setState(() => ({
          error: true,
          password: "",
        }));
      }
    } catch (e) {
      console.error(e);
      console.log("login failed");
    }
  };

  render() {
    return !loggedIn() ? (
      <LoginForm
        email={this.state.email}
        password={this.state.password}
        onChange={(field, value) => this.setState(() => ({ [field]: value }))}
        onSubmit={() => this.login()}
        error={this.state.error}
      />
    ) : null;
  }
}

export default connect((state, props) => ({
  user: state.auth.loggedInUser,
}))(Login);
