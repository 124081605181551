import React from "react";
import HeliaConnectLogo from "../common/icons/helia-connect-logo";
import styles from "./styles";
import config from "../config";
import querystring from "querystring";

const query = querystring.stringify({
  client_id: config.auth.clientId,
  redirect_uri: config.auth.redirectUri,
});

const LoginForm = ({ email, password, onChange, onSubmit, error }) => {
  return (
    <div style={styles.auth}>
      <div style={styles.authHeader}>
        <div>
          <HeliaConnectLogo width={170} />
        </div>
      </div>
      <div style={styles.authLock}>
        <div style={styles.authForm}>
          <h1 style={styles.formTitle}>Log In</h1>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            {error ? (
              <div style={styles.alertError}>
                Invalid email password combination
              </div>
            ) : null}
            <div style={styles.inputScaffold}>
              <label>
                <span style={styles.inputScaffoldLabel}>Email</span>
                <input
                  style={styles.inputScaffoldInput}
                  type="text"
                  name="email"
                  value={email}
                  onChange={(e) => onChange("email", e.target.value)}
                />
              </label>
            </div>
            <div style={styles.inputScaffold}>
              <label>
                <span style={styles.inputScaffoldLabel}>Password</span>
                <input
                  style={styles.inputScaffoldInput}
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => onChange("password", e.target.value)}
                />
              </label>
            </div>
            <button style={styles.button} type="submit">
              Log In
            </button>
          </form>
        </div>
      </div>
      <div style={styles.authHelp}>
        <a
          style={styles.authHelpLink}
          href={`${config.auth.domain}/user/forgot-password?${query}`}
        >
          Forgot Password?
        </a>
      </div>
    </div>
  );
};

export default LoginForm;
