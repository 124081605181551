import React, { Component } from "react";
import { logout } from "../auth/auth-redirects";
import HeliaConnectLogo from "../common/icons/helia-connect-logo";
import styles from "./styles";

class NoPermission extends Component {
  render() {
    return (
      <div style={styles.root}>
        <div style={styles.paper}>
          <HeliaConnectLogo />
          <div style={styles.title}>
            You do not have sufficient permissions to see anything here.
          </div>
          <div style={styles.subTitle}>
            If you believe this is in error, please contact your client account
            team.
          </div>
          <div style={styles.appStores}>
            <p style={styles.subTitle}>
              You may only have access to the Helia Connect app, you can
              download it in your device's app store.
            </p>
            <div style={styles.appStoreButtons}>
              <a
                href="https://itunes.apple.com/us/app/helia-connect/id1371711075?ls=1&mt=8"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://s3-us-west-2.amazonaws.com/assets.heliacare.com/app-store-badge.png"
                  alt="app-store-badge"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.heliaconnect"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={styles.googleButton}
                  src="https://s3-us-west-2.amazonaws.com/assets.heliacare.com/google-play-badge.png"
                  alt="google-play-badge"
                />
              </a>
            </div>
          </div>
          <button style={styles.button} onClick={() => logout()}>
            Logout
          </button>
        </div>
      </div>
    );
  }
}

export default NoPermission;
