import React from "react";
import format from "date-fns/format";
import { Portal } from "react-portal";
import WarningIcon from "../icons/warning";
import styles from "./styles";

const TimeoutModal = ({ logout, timeLeft, onCloseTimeout, isOpened }) => {
  if (!isOpened) {
    return null;
  }
  return (
    <Portal closeOnEsc isOpened={isOpened}>
      <div style={styles.modalRoot}>
        <div style={styles.modalChildren}>
          <div style={styles.modalHeader}>
            <div style={styles.modalTitle}>
              <WarningIcon style={styles.modalTitleIcon} fill="#FF9800" />
              Your session is about to expire
            </div>
          </div>
          <div>
            <p style={styles.timeoutModalInfo}>
              {"For your security, your session will expire in "}
              <span style={styles.timeoutModalInfoSpan}>
                {format(timeLeft, "m:ss")}
              </span>
              {
                ' due to inactivity. If you want to extend your session, please select "Stay logged on". If you select "Logout" or do not respond, your session will be closed out.'
              }
            </p>
            <div style={styles.buttonRow}>
              <button onClick={onCloseTimeout} style={styles.primaryButton}>
                <div style={styles.buttonChildren}>Stay logged on</div>
              </button>
              <button onClick={logout} style={styles.plainButton}>
                <div style={styles.buttonChildren}>Logout</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default TimeoutModal;
