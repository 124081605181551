const styles = {
  root: {
    display: "flex",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F2F2F2",
  },
  auth: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#F2F2F2",
    flexWrap: "nowrap",
    alignItems: "center",
    width: "100%",
    minHeight: "100vh",
    WebkitFontSmoothing: "antialiased",
  },
  authHelp: {
    marginBottom: "30px",
    fontFamily: '"Karla", "Helvetica Neue", sans-serif',
  },
  authHelpLink: {
    color: "#707070",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  alertError: {
    height: "auto",
    margin: "32px 0 24px 0",
    padding: "16px",
    fontWeight: "bold",
    borderRadius: "4px",
    textAlign: "center",
    backgroundColor: "#FF3D00",
    color: "#FFFFFF",
    width: "100%",
    boxSizing: "border-box",
    fontSmoothing: "antialiased",
  },
  paper: {
    backgroundColor: "#FFF",
    borderRadius: "3px",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.15)",
    padding: "32px",
    fontFamily: '"Karla", "Helvetica Neue", sans-serif',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    maxWidth: "420px",
  },
  formTitle: {
    fontSize: "18px",
    marginBottom: "20px",
    fontWeight: "bold",
    color: "#4F4F4F",
    textAlign: "center",
  },
  authHeader: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    width: "100%",
    borderBottom: "1px solid #E5E5E5",
    height: "64px",
    marginBottom: "10%",
  },
  authLock: {
    width: "100%",
    maxWidth: "500px",
    height: "auto",
    background: "#FFFFFF",
    border: "1px solid #E5E5E5",
    borderRadius: "3px",
    marginBottom: "12px",
    fontFamily: '"Karla", "Helvetica Neue", sans-serif',
  },
  authForm: {
    padding: "24px",
  },
  title: {
    fontSize: "24px",
    color: "#010202",
    marginTop: "36px",
  },
  subTitle: {
    marginTop: "20px",
    color: "#4F4F4F",
  },
  inputScaffold: {
    position: "relative",
    display: "block",
    width: "100%",
    padding: "16px 0",
  },
  inputScaffoldLabel: {
    display: "block",
    width: "100%",
    fontSize: "14px",
    fontWeight: "bold",
    marginBottom: "12px",
    color: "#4F4F4F",
  },
  inputScaffoldInput: {
    display: "block",
    width: "100%",
    color: "inherit",
    fontFamily: "inherit",
    fontSize: "16px",
    lineHeight: "20px",
    border: "1px solid #e5e5e5",
    borderRadius: "3px",
    padding: "12px",
    appearance: "none",
    boxSizing: "border-box",
  },
  button: {
    width: "100%",
    padding: "12px 0",
    backgroundColor: "#03A9F4",
    color: "#fff",
    borderRadius: "3px",
    marginTop: "32px",
    fontSize: "16px",
    border: 0,
    cursor: "pointer",
    fontWeight: "bold",
  },
  appStores: {
    borderTop: "1px solid #e5e5e5",
    width: "100%",
    marginTop: "40px",
  },
  appStoreButtons: {
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  googleButton: {
    width: "160px",
    marginLeft: "20px",
  },
  tenantChooser: {
    width: "100%",
    maxWidth: "500px",
    fontFamily: '"Karla", "Helvetica Neue", sans-serif',
    backgroundColor: "white",
    padding: "24px",
    border: "1px solid #E5E5E5",
  },
  tenantChooserHeader: {
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "24px",
    textAlign: "center",
    marginBottom: "16px",
  },
  tenantChooserSubHeader: {
    fontSize: "16px",
    fontWeight: 400,
    textAlign: "center",
    marginBottom: "24px",
  },
  tenantList: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  tenantButtonLeft: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  tenantName: {
    fontSize: "16px",
    marginLeft: "8px",
  },
};

export default styles;
