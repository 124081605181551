import React, { Component } from "react";
import HeliaConnectLogo from "../common/icons/helia-connect-logo";
import RightArrowIcon from "../common/icons/rightArrow";
import styles from "./styles";
import { connect } from "react-redux";
import throttle from "lodash/throttle";
import { fetchLoggedInUser, keepAlive } from "./auth-actions";
import { performAuthenticatedRedirect, logout } from "./auth-redirects";
import TimeoutModal from "../common/TimeoutModal";
import filterTenantAccess from "authenticator/src/auth/filterTenantAccess";

const CHECK_INTERVAL = 5000; // 5 seconds
const PRE_MODAL_INTERVAL = 60000 * 12; // 12 minutes
const MODAL_INTERVAL = 60000 * 3; // 3 minutes
const ONE_SECOND = 1000; // countdown increment

const listenEvents = [
  "click",
  "mousemove",
  "scroll",
  "keypress",
  "touchstart",
  "mousedown",
];

class ChooseTenant extends Component {
  state = {
    timeoutModal: false,
    timeLeft: MODAL_INTERVAL,
    initialRequest: true,
  };

  componentDidMount() {
    if (!this.props.user) {
      const idToken = sessionStorage.getItem("id_token");
      this.props.dispatch(fetchLoggedInUser(idToken));
    }
    this.inactivityTimer();
  }

  componentDidUpdate(prevProps) {
    const { timeLeft } = this.state;

    if (timeLeft <= 0) {
      clearInterval(this.modalCountdown);
      logout();
    }

    if (
      (!prevProps.fetchUserError && this.props.fetchUserError) ||
      this.state.timeLeft <= 0
    ) {
      logout();
    }
  }

  changeBackground(e) {
    e.target.style.background = "rgba(3,169,244, 0.10)";
  }

  revertBackground(e) {
    e.target.style.background = "white";
  }

  inactivityTimer() {
    if (!window.lastActivity) {
      window.lastActivity = Date.now();
    }

    listenEvents.forEach((event) => {
      document.addEventListener(event, this.debouncedActivityListener);
    });

    this.activityChecker = setInterval(this.check, CHECK_INTERVAL);
  }

  debouncedActivityListener = throttle(() => {
    const { dispatch } = this.props;
    const { initialRequest } = this.state;
    const token = sessionStorage.getItem("id_token");

    dispatch(keepAlive(token, { initialRequest })).then((action) => {
      if (initialRequest) {
        this.setState({ initialRequest: false });
      }

      if (action && action.response && action.response.ok) {
        const updatedTimestamp = new Date(action.json);
        window.lastActivity = updatedTimestamp;
      } else if (
        action &&
        action.response &&
        action.response.status === 401 &&
        process.env.REACT_APP_ENV !== "development"
      ) {
        logout();
      }
    });
  }, CHECK_INTERVAL);

  check = () => {
    const currentTime = Date.now();
    if (currentTime - window.lastActivity > PRE_MODAL_INTERVAL) {
      listenEvents.forEach((event) => {
        document.removeEventListener(event, this.debouncedActivityListener);
      });
      this.setState(() => ({ timeoutModal: true }));
      clearInterval(this.activityChecker);

      this.modalCountdown = setInterval(() => {
        this.setState(({ timeLeft }) => ({ timeLeft: timeLeft - ONE_SECOND }));
      }, ONE_SECOND);
    }
  };

  handleCloseSessionTimeoutModal = () => {
    this.setState(() => ({ timeoutModal: false, timeLeft: MODAL_INTERVAL }));
    clearInterval(this.modalCountdown);
    window.lastActivity = Date.now();
    this.inactivityTimer();
  };

  render() {
    const { user, tenantSubdomains } = this.props;

    if (!user) {
      return null;
    }

    return (
      <div style={styles.auth}>
        <div style={styles.authHeader}>
          <div>
            <HeliaConnectLogo width={170} />
          </div>
        </div>
        <div style={styles.tenantChooser}>
          <h1 style={styles.tenantChooserHeader}>
            Welcome back, {user.get("first_name")} {user.get("last_name")}!
          </h1>
          <h2 style={styles.tenantChooserSubHeader}>
            Select an account you want to log in to.
          </h2>
          <div style={styles.tenantList}>
            {tenantSubdomains &&
              tenantSubdomains.map((subdomain, index) => (
                <button
                  style={{
                    width: "100%",
                    padding: "16px",
                    borderTop: index > 0 ? "1px solid #E5E5E5" : null,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  key={subdomain.get("slug")}
                  onClick={() => {
                    performAuthenticatedRedirect(subdomain.get("slug"));
                  }}
                  onMouseEnter={this.changeBackground}
                  onMouseLeave={this.revertBackground}
                >
                  <div style={styles.tenantButtonLeft}>
                    <div
                      style={{
                        backgroundImage: `url(${subdomain.get("logo")})`,
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        width: "32px",
                        height: "32px",
                        borderRadius: "100%",
                      }}
                    />
                    <div style={styles.tenantName}>{subdomain.get("name")}</div>
                  </div>
                  <RightArrowIcon width={20} height={20} fill="#4F4F4F" />
                </button>
              ))}
          </div>
        </div>
        <TimeoutModal
          timeLeft={this.state.timeLeft}
          logout={logout}
          onCloseTimeout={this.handleCloseSessionTimeoutModal}
          isOpened={this.state.timeoutModal}
        />
      </div>
    );
  }
}

export default connect((state, props) => ({
  fetchUserError: state.auth.loggedInUser.get("error"),
  user: state.auth.loggedInUser.getIn(["data", "user"]),
  tenantSubdomains: filterTenantAccess(state, props),
}))(ChooseTenant);
