import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Login from "./auth/Login";
import PostLogout from "./auth/PostLogout";
import NoPermission from "./auth/NoPermission";
import ChooseTenant from "./auth/ChooseTenant";

export default class App extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/post-logout" component={PostLogout} />
        <Route exact path="/no-permission" component={NoPermission} />
        <Route exact path="/choose-tenant" component={ChooseTenant} />
      </Switch>
    );
  }
}
