import client from "../client";
import config from "../config";

export const FETCH_LOGGED_IN_USER = "FETCH_LOGGED_IN_USER";

export function fetchLoggedInUser(jwt) {
  const request = new Request(`${config.apiUrl}user`, {
    headers: { Authorization: `Bearer ${jwt}` },
  });

  return {
    type: FETCH_LOGGED_IN_USER,
    promise: client.fetch(request),
  };
}

export const USER_INACTIVITY_CHECK = "USER_INACTIVITY_CHECK";

export function keepAlive(token, options) {
  return {
    type: USER_INACTIVITY_CHECK,
    promise: client.post(`keepalive?token=${token}`, options),
  };
}
