import React from "react";

const HeliaConnectLogo = ({ width, height }) => {
  return (
    <svg
      width={width || "362"}
      height={height || "71.777"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 426.77 84.62"
    >
      <path
        fill="#2d2d2d"
        d="M76.74,265.2H70.6V240.33H42.76V265.2H36.61V212.35h6.15v22.48H70.6V212.35h6.15V265.2Z"
        transform="translate(-36.61 -197.96)"
      />
      <path
        fill="#2d2d2d"
        d="M105.07,265.92q-8.79,0-13.86-5.35t-5.08-14.86q0-9.59,4.72-15.22a15.66,15.66,0,0,1,12.67-5.64q7.44,0,11.78,4.9t4.34,12.92v3.8H92.34q.18,7,3.52,10.59t9.42,3.61A31.78,31.78,0,0,0,117.93,258v5.35a31.66,31.66,0,0,1-6,2A32.72,32.72,0,0,1,105.07,265.92Zm-1.63-36A9.81,9.81,0,0,0,95.83,233a14.28,14.28,0,0,0-3.34,8.6H113.2q0-5.67-2.53-8.69A8.92,8.92,0,0,0,103.44,229.88Z"
        transform="translate(-36.61 -197.96)"
      />
      <path
        fill="#2d2d2d"
        d="M133.87,265.2h-6V209h6Z"
        transform="translate(-36.61 -197.96)"
      />
      <path
        fill="#2d2d2d"
        d="M144.08,214.84a4,4,0,0,1,1-3,3.73,3.73,0,0,1,5,0,3.91,3.91,0,0,1,1,3,4,4,0,0,1-1,3,3.51,3.51,0,0,1-2.49,1,3.47,3.47,0,0,1-2.53-1A4.08,4.08,0,0,1,144.08,214.84Zm6.51,50.36h-6V225.58h6Z"
        transform="translate(-36.61 -197.96)"
      />
      <path
        fill="#2d2d2d"
        d="M185.68,265.2l-1.19-5.64h-.29a16.75,16.75,0,0,1-5.91,5,18,18,0,0,1-7.36,1.32q-5.9,0-9.24-3t-3.34-8.64q0-12,19.19-12.58l6.72-.22V239c0-3.11-.67-5.4-2-6.89s-3.48-2.22-6.42-2.22a26.1,26.1,0,0,0-11.21,3l-1.84-4.59a28.87,28.87,0,0,1,13.41-3.39q7.08,0,10.5,3.14t3.42,10.09v27ZM172.12,261c3.74,0,6.67-1,8.8-3.07s3.2-4.92,3.2-8.6v-3.58l-6,.25q-7.16.25-10.32,2.22a6.7,6.7,0,0,0-3.16,6.13,6.18,6.18,0,0,0,2,4.95A8.18,8.18,0,0,0,172.12,261Z"
        transform="translate(-36.61 -197.96)"
      />
      <path
        fill="#2d2d2d"
        d="M238.25,217.08q-8.72,0-13.75,5.8t-5,15.89q0,10.38,4.86,16t13.86,5.66a47.21,47.21,0,0,0,12.62-2v5.39a38.93,38.93,0,0,1-13.56,2.06q-11.67,0-18-7.08t-6.34-20.13a31.81,31.81,0,0,1,3.05-14.31,22,22,0,0,1,8.82-9.47,26.66,26.66,0,0,1,13.57-3.33,32.66,32.66,0,0,1,14.53,3l-2.6,5.28A27.74,27.74,0,0,0,238.25,217.08Z"
        transform="translate(-36.61 -197.96)"
      />
      <path
        fill="#2d2d2d"
        d="M283.79,265.2l-1.19-5.64h-.29a16.75,16.75,0,0,1-5.91,5,18,18,0,0,1-7.36,1.32q-5.9,0-9.24-3t-3.34-8.64q0-12,19.19-12.58l6.72-.22V239c0-3.11-.67-5.4-2-6.89s-3.48-2.22-6.42-2.22a26.1,26.1,0,0,0-11.21,3l-1.84-4.59a28.87,28.87,0,0,1,13.41-3.39q7.08,0,10.5,3.14t3.42,10.09v27ZM270.23,261c3.74,0,6.67-1,8.8-3.07s3.2-4.92,3.2-8.6v-3.58l-6,.25q-7.16.25-10.32,2.22a6.7,6.7,0,0,0-3.16,6.13,6.18,6.18,0,0,0,2,4.95A8.18,8.18,0,0,0,270.23,261Z"
        transform="translate(-36.61 -197.96)"
      />
      <path
        fill="#2d2d2d"
        d="M316.67,224.86a23.49,23.49,0,0,1,4.74.43l-.83,5.57a20.57,20.57,0,0,0-4.34-.54,10.54,10.54,0,0,0-8.22,3.9,14.21,14.21,0,0,0-3.42,9.72v21.25h-6V225.57h4.95l.69,7.34h.29a17.46,17.46,0,0,1,5.31-6A12.07,12.07,0,0,1,316.67,224.86Z"
        transform="translate(-36.61 -197.96)"
      />
      <path
        fill="#2d2d2d"
        d="M343.55,265.92q-8.79,0-13.86-5.35t-5.08-14.86q0-9.59,4.72-15.22A15.66,15.66,0,0,1,342,224.85q7.44,0,11.78,4.9t4.34,12.92v3.8H330.83q.18,7,3.52,10.59t9.42,3.61A31.78,31.78,0,0,0,356.42,258v5.35a31.66,31.66,0,0,1-6,2A32.82,32.82,0,0,1,343.55,265.92Zm-1.63-36a9.81,9.81,0,0,0-7.61,3.11,14.28,14.28,0,0,0-3.34,8.6h20.71q0-5.67-2.53-8.69A8.9,8.9,0,0,0,341.92,229.88Z"
        transform="translate(-36.61 -197.96)"
      />
      <circle fill="#24a6e8" cx="384.46" cy="42.31" r="42.31" />
      <g>
        <path
          fill="#FFFFFF"
          d="M443.11,256.05a2.61,2.61,0,0,1-3.57-.93l-22.26-38.21a2.62,2.62,0,0,1,4.52-2.64l22.26,38.21A2.6,2.6,0,0,1,443.11,256.05Z"
          transform="translate(-36.61 -197.96)"
        />
        <path
          fill="#FFFFFF"
          d="M425.73,257.62a2.62,2.62,0,0,1-3.58-.94L407.75,232a2.62,2.62,0,0,1,4.52-2.64L426.68,254A2.63,2.63,0,0,1,425.73,257.62Z"
          transform="translate(-36.61 -197.96)"
        />
        <path
          fill="#FFFFFF"
          d="M408.08,258.73a2.61,2.61,0,0,1-3.58-.94L398,246.57a2.62,2.62,0,0,1,4.53-2.64L409,255.15A2.63,2.63,0,0,1,408.08,258.73Z"
          transform="translate(-36.61 -197.96)"
        />
        <path
          fill="#FFFFFF"
          d="M423.43,269.41a2.64,2.64,0,0,1-3.62-1l-22.52-38.65a2.65,2.65,0,1,1,4.58-2.67l22.52,38.66A2.64,2.64,0,0,1,423.43,269.41Z"
          transform="translate(-36.61 -197.96)"
        />
        <path
          fill="#FFFFFF"
          d="M432.92,254.28a2.65,2.65,0,0,1-3.62-.95l-14.57-25a2.65,2.65,0,1,1,4.58-2.67l14.57,25A2.67,2.67,0,0,1,432.92,254.28Z"
          transform="translate(-36.61 -197.96)"
        />
        <path
          fill="#FFFFFF"
          d="M442.66,239.6a2.64,2.64,0,0,1-3.62-.95l-6.62-11.35a2.65,2.65,0,0,1,4.58-2.67L443.62,236A2.66,2.66,0,0,1,442.66,239.6Z"
          transform="translate(-36.61 -197.96)"
        />
      </g>
    </svg>
  );
};

export default HeliaConnectLogo;
