import React from "react";

const HeliaConnectLogo = ({ width, height, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <path
        d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"
        fill={fill || "#000000"}
      />
    </svg>
  );
};

export default HeliaConnectLogo;
