import Immutable from "immutable";
import { combineReducers } from "redux";
import getApiReducer from "../common/utils/getApiReducer";
import { FETCH_LOGGED_IN_USER } from "./auth-actions";

const loggedInUser = (state = Immutable.Map(), action) => {
  if (action.originalType === FETCH_LOGGED_IN_USER) {
    state = getApiReducer(FETCH_LOGGED_IN_USER)(state, action);
  }

  if (action.type === `${FETCH_LOGGED_IN_USER}_SUCCESS`) {
    state = state.setIn(["data", "getRoleId"], function () {
      return this.roles;
    });
  }

  return state;
};

export default combineReducers({
  loggedInUser,
});
