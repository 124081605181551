import React, { Component } from "react";
import { Link } from "react-router-dom";
import HeliaConnectLogo from "../common/icons/helia-connect-logo";
import styles from "./styles";

export default class PostLogout extends Component {
  componentDidMount() {
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("id_token");
    sessionStorage.removeItem("refresh_token");
    sessionStorage.removeItem("slug");
  }

  render() {
    return (
      <div style={styles.root}>
        <div style={styles.paper}>
          <HeliaConnectLogo />
          <div style={styles.title}>You've been successfully logged out.</div>
          <Link style={{ width: "100%" }} to="/">
            <button style={styles.button}>Login</button>
          </Link>
        </div>
      </div>
    );
  }
}
