import localstorage from "store2";
import * as uuid from "uuid";
import config from "../config";
import Cookies from "js-cookie";

export function login() {
  const nonce = uuid();
  sessionStorage.setItem("nonce", nonce);
  window.location.href =
    `${config.auth.domain}/op/auth?client_id=${config.auth.clientId}` +
    `&response_type=${config.auth.responseType}&scope=${config.auth.scope}` +
    `&redirect_uri=${config.auth.redirectUri}&nonce=${nonce}`;
}

export function logout() {
  localstorage.clearAll(); // clear pusherTransportEncrypted
  sessionStorage.clear();
  window.location.href = `${config.auth.domain}/user/logout?post_logout_redirect_uri=${config.auth.postLogoutRedirectUri}`;
}

export function performAuthenticatedRedirect(subdomain) {
  const sessionKey = uuid.v4().replace("-", "").slice(0, 12);
  // Securely communicating tokens to other subdomains with cookies
  // we expect these cookies to be immediately deleted on the other side
  // of the redirect.
  Cookies.set(
    `redirect-accessToken-${sessionKey}`,
    sessionStorage.getItem("access_token"),
    { domain: `${config.cookieDomain}` }
  );
  Cookies.set(
    `redirect-idToken-${sessionKey}`,
    sessionStorage.getItem("id_token"),
    { domain: `${config.cookieDomain}` }
  );
  Cookies.set(
    `redirect-refreshToken-${sessionKey}`,
    sessionStorage.getItem("refresh_token"),
    { domain: `${config.cookieDomain}` }
  );
  console.log({
    destination: `${window.location.protocol}//${subdomain}.${config.appDomain}/post-login?sessionKey=${sessionKey}`,
  });
  window.location.href = `${window.location.protocol}//${subdomain}.${config.appDomain}/post-login?sessionKey=${sessionKey}`;
}
