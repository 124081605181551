import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route } from "react-router-dom";
import URLSearchParams from "url-search-params";

import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevToolsDevelopmentOnly } from "@redux-devtools/extension";

import thunk from "redux-thunk";

import { unregister } from "./service-worker-registration";
import apiRequestMiddleware from "./common/utils/apiRequestMiddleware";
import reducers from "./reducers";
import App from "./App";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import config from "./config";
import { version } from "../package.json";

import "./reset.css";

// Polyfill URLSearchParams so fetch-client works in IE
window.URLSearchParams = URLSearchParams;

Sentry.init({
  dsn: "https://aeb97c8c3f1d491f92385bf4c0313b4b@sentry.io/272018",
  release: version,
  environment: process.env.REACT_APP_ENV,
  integrations: [
    new BrowserTracing({
      tracingOrigins: [config.apiUrl.split("https://")[1]],
    }),
  ],
  maxBreadcrumbs: 50,
  tracesSampleRate: 0.2,
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

// Apply Redux middleware
let storeEnhancer = composeWithDevToolsDevelopmentOnly(
  applyMiddleware(apiRequestMiddleware, thunk),
  sentryReduxEnhancer
);

// Bootstrap the React app
const reduxStore = storeEnhancer(createStore)(reducers);
ReactDOM.render(
  <Provider store={reduxStore}>
    <BrowserRouter>
      <div>
        <Route component={App} />
      </div>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

unregister();
