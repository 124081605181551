import merge from "./common/utils/merge";

const defaults = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    redirectUri: "https://synapse.codes",
    postLogoutRedirectUri: "https://synapse.codes/post-logout",
    domain: "https://accounts.synapse.codes",
    scope: "openid email app_metadata profile",
    responseType: "code id_token token",
  },
  appDomain: "synapse.codes",
  cookieDomain: ".synapse.codes",
  validationMessages: {},
};

const MAX_LEN = 64 - 10;
const getQaUrl = (app) => {
  let qaUrlBase = `${process.env.REACT_APP_CS_PROJECT}-${process.env.REACT_APP_CS_REPO}`;
  let oidcBase = `${qaUrlBase}-${app}-${process.env.REACT_APP_CS_BRANCH}`;
  oidcBase = oidcBase.toLowerCase().substr(0, MAX_LEN);
  return oidcBase + ".synsit.es";
};

const environments = {
  development: {
    apiUrl: "https://api.synapse.codes/",
  },
  qa: {
    apiUrl: `https://${getQaUrl("api")}/`,
    auth: {
      redirectUri: `https://${getQaUrl("authenticator")}`,
      postLogoutRedirectUri: `https://${getQaUrl("authenticator")}`,
      domain: `https://${getQaUrl("oidc")}`,
    },
    appDomain: getQaUrl("frontend"),
    cookieDomain: ".synsit.es",
  },
  ci: {
    apiUrl: `http://api.synapse.codes/`,
    auth: {
      auth: {
        redirectUri: "http://synapse.codes",
        postLogoutRedirectUri: "http://synapse.codes/post-logout",
        domain: "http://accounts.synapse.codes",
      },
    },
    appDomain: "synapse.codes",
    cookieDomain: ".synapse.codes",
  },
  staging: {
    apiUrl: "https://api.staging.heliacare.com/",
    auth: {
      clientId: "aebce7c0-7256-4ac9-9566-34a99e7fa31f",
      redirectUri: "https://staging.heliacare.com",
      postLogoutRedirectUri: "https://staging.heliacare.com",
      domain: "https://accounts.staging.heliacare.com",
      scope: "openid email app_metadata profile",
      responseType: "code id_token token",
    },
    appDomain: "staging.heliacare.com",
    cookieDomain: ".heliacare.com",
  },
  production: {
    apiUrl: "https://api.heliaconnect.com/",
    auth: {
      clientId: "22d90191-7a83-4c9a-9a11-4f9834357a72",
      redirectUri: "https://heliaconnect.com",
      postLogoutRedirectUri: "https://heliaconnect.com/post-logout",
      domain: "https://accounts.heliaconnect.com",
      scope: "openid email app_metadata profile",
      responseType: "code id_token token",
    },
    appDomain: "heliaconnect.com",
    cookieDomain: ".heliaconnect.com",
  },
};

export default merge(
  defaults,
  process.env.REACT_APP_ENV
    ? environments[process.env.REACT_APP_ENV]
    : environments["development"]
);
